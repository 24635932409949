/***********STYLE BASIC********************/
@import url('https://fonts.googleapis.com/css?family=Roboto');


  html,
  body,
  #app-root {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  body {
    font-family: -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  }

  .ui.grid {
    margin: 0;
  }

  .pushable > .pusher {
    overflow-y: auto;
  }

  h1, h2, h3, h4, h5, h6, .ui.header {
    color: #888;
    font-family: 'Roboto', sans-serif;
  }

  .header-small {
    color: #888;
    font-size: 0.92857143em;
    font-weight: 600;
    display: block;
    padding-top: .5em;
    padding-bottom: .5em;
  }

  .asper {
    color: #e74c3c;
  }

  .pushable > .pusher {
    width: 100%;
  }

  .pushable > .pusher {
    -webkit-transition: -webkit-transform 500ms ease, width 450ms ease;
    -o-transition: transform 500ms ease, width 450ms ease;
    transition: transform 500ms ease, -webkit-transform 500ms ease, width 450ms ease;
  }
/*****************************************/

/*****************FORMs******************/
  .ui.form {
    font-size: 13px;
  }
  .ui.form input:not([type]),
  .ui.form input[type="text"] {
    border-radius: 0;
  }

  .ui.form .field,
  .ui.form .field:last-child,
  .ui.form .fields .field,
  .ui.form .fields:last-child .field {
    margin-bottom: 2em;
  }

  ui.form input:hover,
  ui.form input:active,
  ui.form input:focus,
  ui.form .error input {
    border-color: #F44336;
  }

  .ui.form .field > label {
    color: rgba(0, 0, 0, 0.65);
  }

  .ui.form .field > label {
    color: rgba(0, 0, 0, 0.65);
    font-weight: normal;
    font-size: 14px;
  }
/*****************************************/

/*****************BUTTONS****************/
  .ui.blue.buttons .button,
  .ui.blue.button {
    background-color: #286efa;
  }

  .ui.blue.buttons .button:hover,
  .ui.blue.button:hover,
  .ui.blue.buttons .button:active,
  .ui.blue.button:active,
  .ui.blue.buttons .button:focus,
  .ui.blue.button:focus {
    background-color: #2856fa;
  }

  .ui.basic.buttons .button,
  .ui.basic.button {
    font-weight: 600;
  }

  .ui.basic.red.buttons .button:hover,
  .ui.basic.red.button:hover,
  .ui.basic.red.buttons .button:active,
  .ui.basic.red.button:active {
    background: #d019190d !important;
    -webkit-box-shadow: 0px 0px 0px 1px #d01919 inset !important;
    box-shadow: 0px 0px 0px 1px #d01919 inset !important;
    color: #d01919 !important;
  }

  .ui.green.buttons .button,
  .ui.green.button {
    background-color: #1abc9c;
  }

  .ui.green.buttons .button:hover,
  .ui.green.button:hover,
  .ui.green.buttons .button:active,
  .ui.green.button:active,
  .ui.green.buttons .button:focus,
  .ui.green.button:focus {
    background-color: #16a085;
  }
/*****************************************/

/***********INPUT AUTO COMPELTE***********/
  @-webkit-keyframes autofill {
    to {
        color: #666;
        background: transparent;
    }
  }

  input:-webkit-autofill {
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
  }

/*****************************************/

/*****************TOASTR*****************/
  .redux-toastr .toastr .close-toastr {
    color: #FFF;
    opacity: .6;
  }

  .redux-toastr .toastr:hover:not(.rrt-message) {
    box-shadow: none;
  }

  .redux-toastr .toastr {
    border-radius: 0;
  }

  .redux-toastr .toastr.rrt-success {
    background-color: #1abc9c;
  }

  .redux-toastr .toastr.rrt-success .rrt-progressbar {
    background-color: #16a085;
  }
/*****************************************/

/*****************MODAL******************/
  .ui.dimmer {
    background-color: rgba(0, 0, 0, 0.55);
  }

  .ui.modal .form > .actions {
    background: #F9FAFB;
    padding: 1rem 1rem;
    border-top: 1px solid rgba(34, 36, 38, 0.15);
    text-align: right;
  }
/*****************************************/

body ::-webkit-scrollbar-track {
  box-shadow: none;
  background-color: #F2F6FF;
}
body ::-webkit-scrollbar {
  width: 6px;
  height: 10px;
  background-color: #F5F5F5;
}
body ::-webkit-scrollbar-thumb {
  background-color: rgba(180, 183, 184, 0.69);
}

body .cke_widget_wrapper:hover>.cke_widget_element {
  outline: none !important;
}

body ::placeholder,
body ::-webkit-input-placeholder,
body ::-moz-placeholder,
body ::-ms-input-placeholder {
  color: #adadad;
  opacity: 1;
}

.ant-input-number-input {
  background-color: #fafafa;
}